import { api } from "../../api"
import { setAlertFunc } from "../../components/AlertToaster/AlertSlice"
import {
    HistoryResponse,
    IFile,
    IResponse,
    SiteIdObj,
    StatusButton,
    TabsResponseType,
    TicketDataResponseType,
} from "../Common/commonTypes"
import { setLogisticsDealDetailsData } from "./LogisticsSlice"

const MODULE_URL = `logistics`

type ticket_obj = {
    display_id: string
    assigned_to: string | number
    status: string
}

type OpsObj = ticket_obj & {
    name: string
    ticket_id: number
}

type FinanceObj = ticket_obj & {
    fin_ticket_id: number
}

export type OtherDetailsResponseObj = {
    deal_obj: {
        client_poc: string
        completion_date: string
        coordinates: string
        cpo: string
        dist_gstin: null | string
        distributer: null | string
        hosting_address: string
        location: string
        pincode: number
        property_type: string
        station_type: string
        survey_ticket: number
    }
    ops_obj: OpsObj
    finance_obj: FinanceObj
}

export type TransporterObj = {
    gstin: string
    id: number
    name: string
    status: string
    transporter_id: string
}

type AddressObj = {
    city_id: number
    city_name: string
    hosting_address: string
    latitude: number
    longitude: number
    pincode: number
    site_id: string
    state_id: number
    state_name: string
}

const logisticsService = api.injectEndpoints({
    endpoints: build => ({
        getLogisticsTabs: build.query({
            query: params => ({
                url: `${MODULE_URL}/total`,
                params: {
                    source_state_id: JSON.stringify(params.data.stateId ?? []),
                    destination_state_id: JSON.stringify(params.data.destinationstateId ?? []),
                    status_id: JSON.stringify(params.data.statusId ?? []),
                    source_city_id: JSON.stringify(params.data.cityId ?? []),
                    destination_city_id: JSON.stringify(params.data.destinationcityId ?? []),
                    assigned_to: params.data.assigned_to?.id ?? "",
                    vendor_id: params.data.delivery_vendor?.id ?? "",
                    age: JSON.stringify(params.data.age ?? []),
                    age_op: params.data.age_op ?? "",
                    raised_on_end: params.data.TicketEndDate ?? "",
                    raised_on_start: params.data.TicketStartDate ?? "",
                    etd_start: params.data.ETDStartDate ?? "",
                    etd_end: params.data.ETDEndDate ?? "",
                    charger_type: JSON.stringify(params.data.chargerIds ?? []),
                    search_key: params.data.search_key ?? "",
                    raised_by_id: params.data.raised_by_id ?? "",
                    read_filter: params.data.ReadUnread ?? "all",
                    scope_of_work: params.data.sow ?? "",
                    ticket_type: params.data.ticketType ?? "all",
                    per_page: params?.selectedValue,
                    page: params?.page,
                },
            }),
            transformResponse: (response: TabsResponseType) => response.data.message,
            providesTags: ["Logistics"],
        }),
        getLogisticsData: build.query<TicketDataResponseType, { data: any; selectedValue: number; page: number }>({
            query: params => ({
                url: `${MODULE_URL}/${params.data.parent_status ?? "all_tab"}`,
                params: {
                    source_state_id: JSON.stringify(params.data.stateId ?? []),
                    destination_state_id: JSON.stringify(params.data.destinationstateId ?? []),
                    status_id: JSON.stringify(params.data.statusId ?? []),
                    source_city_id: JSON.stringify(params.data.cityId ?? []),
                    destination_city_id: JSON.stringify(params.data.destinationcityId ?? []),
                    assigned_to: params.data.assigned_to?.id ?? "",
                    vendor_id: params.data.delivery_vendor?.id ?? "",
                    age: JSON.stringify(params.data.age ?? []),
                    age_op: params.data.age_op ?? "",
                    raised_on_end: params.data.TicketEndDate ?? "",
                    raised_on_start: params.data.TicketStartDate ?? "",
                    etd_start: params.data.ETDStartDate ?? "",
                    etd_end: params.data.ETDEndDate ?? "",
                    charger_type: JSON.stringify(params.data.chargerIds ?? []),
                    search_key: params.data.search_key ?? "",
                    raised_by_id: params.data.raised_by_id ?? "",
                    read_filter: params.data.ReadUnread ?? "all",
                    ticket_type: params.data.ticketType ?? "all",
                    scope_of_work: params.data.sow ?? "",
                    per_page: params?.selectedValue,
                    page: params?.page,
                },
            }),
            providesTags: ["Logistics"],
        }),
        getLogisticsTicketDetails: build.query<IResponse<any>, { ticket_id: string }>({
            query: ({ ticket_id }) => ({
                url: `${MODULE_URL}/ticket_details`,
                params: {
                    ticket_id,
                },
            }),
            providesTags: (result, error, args) => [{ type: "Logistics", id: args?.ticket_id }],
            async onQueryStarted(params, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled // Get the response data
                    // Dispatch the data to the logistics slice to save it in Redux state
                    dispatch(setLogisticsDealDetailsData(data))
                } catch (error) {
                    console.error("Failed to fetch ticket details", error)
                }
            },
        }),
        getLogisticsOtherDetails: build.query<IResponse<OtherDetailsResponseObj>, { ticket_id: string }>({
            query: ({ ticket_id }) => ({
                url: `${MODULE_URL}/other_details`,
                params: {
                    ticket_id,
                },
            }),
            providesTags: (result, error, args) => [{ type: "Logistics", id: args.ticket_id }],
        }),
        getLogisticsTicketHistory: build.query<HistoryResponse, { ticket_id: string }>({
            query: ({ ticket_id }) => ({
                url: `${MODULE_URL}/history`,
                params: {
                    ticket_id,
                },
            }),
            providesTags: (result, error, args) => [{ type: "Logistics", id: args.ticket_id }],
        }),
        getLogisticsButtonDropdown: build.query<IResponse<StatusButton[]>, { ticket_id: string }>({
            query: ({ ticket_id }) => ({
                url: `${MODULE_URL}/button`,
                params: {
                    ticket_id,
                },
            }),
            providesTags: (result, error, args) => [{ type: "Logistics", id: args.ticket_id }],
        }),
        getAllLogisticsTicketStatus: build.query<
            IResponse<{ id: number; name: string }>,
            { search_key: string } | void
        >({
            query: params => ({
                url: `${MODULE_URL}/statuses`,
                params: {
                    search_key: params?.search_key ?? "",
                },
            }),
            providesTags: ["Logistics"],
        }),
        getAllSiteIds: build.query<IResponse<SiteIdObj[]>, void>({
            query: () => ({
                url: `${MODULE_URL}/site_id`,
            }),
            providesTags: ["Projects", "Logistics"],
        }),
        getGsrTemplate: build.query<IFile, void>({
            query: () => ({
                url: `${MODULE_URL}/gsr_template`,
            }),
            transformResponse: (response: [IFile, number]) => response[0],
            providesTags: ["Settings"],
        }),
        getAllProjects: build.query<{ id: number; name: string }[], void>({
            query: () => ({
                url: `${MODULE_URL}/projects_list`,
            }),
            transformResponse: (response: IResponse<{ id: number; name: string }[]>) => response.data,
            providesTags: ["Projects"],
        }),
        getDeliveryVendors: build.query<TransporterObj[], void>({
            query: () => ({
                url: `${MODULE_URL}/delivery_vendors`,
            }),
            transformResponse: (response: IResponse<TransporterObj[]>) => response.data,
            providesTags: ["Transporters"],
        }),
        getLogisitcsFormAutofill: build.query<AddressObj, { id: number }>({
            query: ({ id }) => ({
                url: `${MODULE_URL}/form_autofill`,
                params: {
                    id,
                },
            }),
            transformResponse: (response: IResponse<AddressObj>) => response.data,
        }),

        // Mutations
        addSoloLogisticsTicket: build.mutation<IResponse<string>, FormData>({
            query: body => ({
                url: `${MODULE_URL}/ticket`,
                method: "POST",
                body: body,
            }),
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled
                    dispatch(setAlertFunc({ type: "success", msg: "Ticket Created!" }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error?.message }))
                }
            },
            invalidatesTags: ["Logistics"],
        }),
        editSoloLogisticsTicket: build.mutation<IResponse<string>, FormData>({
            query: body => ({
                url: `${MODULE_URL}/edit_solo_ticket`,
                method: "PUT",
                body: body,
            }),
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled
                    dispatch(setAlertFunc({ type: "success", msg: data?.data }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error?.message }))
                }
            },
            invalidatesTags: ["Logistics"],
        }),
        linkSoloLogisticsTicket: build.mutation<IResponse<{ msg: string }>, FormData>({
            query: body => ({
                url: `${MODULE_URL}/link_solo_log`,
                method: "PUT",
                body: body,
            }),
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled
                    dispatch(setAlertFunc({ type: "success", msg: data.data.msg }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error?.message }))
                }
            },
            invalidatesTags: ["Logistics"],
        }),
        editLogisticsTicketStatus: build.mutation<IResponse<string[]>, FormData>({
            query: body => ({
                url: `${MODULE_URL}/ticket`,
                method: "PUT",
                body: body,
            }),
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled
                    dispatch(setAlertFunc({ type: "success", msg: data?.data[0] }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error?.message }))
                }
            },
            invalidatesTags: ["Logistics"],
        }),
        dropSoloLogisticsTicket: build.mutation<IResponse<string>, FormData>({
            query: body => ({
                url: `${MODULE_URL}/drop_solo_ticket`,
                method: "PUT",
                body: body,
            }),
            async onQueryStarted(params, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    dispatch(
                        setAlertFunc({
                            type: "success",
                            msg: data?.data,
                        })
                    )
                } catch (error: any) {
                    dispatch(
                        setAlertFunc({
                            type: "error",
                            msg: error?.message,
                        })
                    )
                    console.error("Failed to drop ticket", error)
                }
            },
            invalidatesTags: ["Logistics"],
        }),
        logisticsSkipToStatus: build.mutation<IResponse<{ message: string }>, FormData>({
            query: body => ({
                url: `${MODULE_URL}/skip_to_status`,
                method: "PUT",
                body: body,
            }),
            async onQueryStarted(params, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    dispatch(
                        setAlertFunc({
                            type:
                                data.status_code === 200 && data.data.message !== "Access denied" ? "success" : "error",
                            msg: data?.data?.message,
                        })
                    )
                } catch (error) {
                    console.error("Failed to fetch ticket details", error)
                }
            },
            invalidatesTags: ["Logistics"],
        }),
        // Mutation to add Purchase orders
        addLogisticsTicketPurchaseOrder: build.mutation<IResponse<[string]>, FormData>({
            query: body => ({
                url: `${MODULE_URL}/ticket_details`,
                method: "POST",
                body: body,
            }),
            async onQueryStarted(params, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    dispatch(
                        setAlertFunc({
                            type: "success",
                            msg: data?.data[0],
                        })
                    )
                } catch (error) {
                    console.error("Failed to fetch ticket details", error)
                }
            },
            invalidatesTags: (result, error, args) => [{ type: "Logistics", id: args.get("ticket_id") as string }],
        }),
        // Mutation to delete Purchase orders
        deleteLogisticsTicketPurchaseOrder: build.mutation<
            IResponse<[string]>,
            { ticket_id: string; purchase_orders: number }
        >({
            query: params => ({
                url: `${MODULE_URL}/ticket_details`,
                method: "DELETE",
                params: params,
            }),
            async onQueryStarted(params, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    dispatch(
                        setAlertFunc({
                            type: "success",
                            msg: data?.data[0],
                        })
                    )
                } catch (error) {
                    console.error("Failed to fetch ticket details", error)
                }
            },
            invalidatesTags: (result, error, args) => [{ type: "Logistics", id: args.ticket_id }],
        }),
    }),
})

export const {
    useLazyGetLogisticsTabsQuery,
    useLazyGetLogisticsDataQuery,
    useLazyGetAllLogisticsTicketStatusQuery,
    useLazyGetLogisitcsFormAutofillQuery,

    useGetLogisticsTicketDetailsQuery,
    useGetLogisticsOtherDetailsQuery,
    useGetLogisticsTicketHistoryQuery,
    useGetLogisticsButtonDropdownQuery,
    useGetAllSiteIdsQuery,
    useGetGsrTemplateQuery,
    useGetAllProjectsQuery,
    useGetDeliveryVendorsQuery,

    useAddSoloLogisticsTicketMutation,
    useEditSoloLogisticsTicketMutation,
    useLinkSoloLogisticsTicketMutation,
    useEditLogisticsTicketStatusMutation,
    useDropSoloLogisticsTicketMutation,
    useLogisticsSkipToStatusMutation,
    useAddLogisticsTicketPurchaseOrderMutation,
    useDeleteLogisticsTicketPurchaseOrderMutation,
} = logisticsService

export { logisticsService }
