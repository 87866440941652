import axios from 'axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { useEncrypt } from '../../../hooks/useEncrypt'
import { axiosInstance } from './axiosInterceptors'
import urls from "../../../enums/urlList";

const initialState: any = {
    verifyUser: [],
    isAuth: (localStorage.getItem('isAuth') !== undefined || localStorage.getItem('isAuth') !== null) ? Boolean(localStorage.getItem('isAuth')) : false,
    otp_token: null,
    token: null,
    user_id: 0,
    loginData: [],
    refreshToken: null,
    verifyLoginLoading: false,
    loginLoading: false,
    modulesList: [],
    rolesArr: [],
    redirectModule: ""
}

export const getlogin = createAsyncThunk(
    'Verifylogin/login', async (obj: any, thunkAPI) => {
        try {
            const response: any = await axios(`${urls.base_url}login/login?phone=${obj.phone}`, {
                method: 'GET',
                headers: {
                    "otp-token": obj.otp_token
                },
                data: JSON.stringify(thunkAPI)
            })
            return response.data;
        } catch (error: any) {
            if (error.response.status === 403) {
                localStorage.clear();
                window.location.replace('/auth');
            }
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    })
export const getRefreshToken = createAsyncThunk(
    'refreshToken', async (thunkAPI: any, { dispatch }) => {
        try {
            const response: any = await axios(`${urls.base_url}jwt/generate-new-tokens?user_id=${thunkAPI.id}`, {
                method: 'GET',
                headers: {
                    "Refresh-Token": thunkAPI.refreshToken
                },
            })
            response.status === 200 && window.location.reload();
            return response.data;
        } catch (error: any) {
            if (error.response.status === 403) {
                localStorage.clear();
                window.location.replace('/auth');
            }
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    })


export const verifyOTP: any = createAsyncThunk(
    'Verifylogin/send-otp',
    async (thunkApi: any, { dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`login/verify-otp`, {
                method: 'POST',
                data: thunkApi
            })
            response.status === 200 && dispatch(getlogin({ 'otp_token': response?.data?.token?.otp_token, 'phone': thunkApi.phone }))
            return response
        }
        catch (err: any) {
            thunkApi.setErrorMessage(err?.response?.data?.message || err?.message);
        }
    }
);

const verifyLoginSlice = createSlice({
    name: 'Verifylogin',
    initialState,
    reducers: {

        setToken(state) {
            let isAuth = localStorage.getItem('isAuth') ? localStorage.getItem('isAuth') : "false";
            let userInfo = localStorage.getItem('userData') ? window.atob(localStorage.getItem('userData')!) : "";
            if (isAuth == 'true') {
                let data = userInfo !== null && JSON.parse(userInfo!)
                if (data !== null) {
                    state.token = data?.authentication?.token?.access_token
                    state.user_id = data?.user_data?.user_id
                    state.loginData = data
                    state.modulesList = data?.modules_list?.modules
                    state.redirectModule = data?.module_page
                }

            }
        },
        deleteToken(state) {
            // 

            state.token = null
            state.user_id = 0
            state.loginData = []
            state.modulesList = []
            state.isAuth = false
            localStorage.clear();

        }
    },
    extraReducers: (builder: any) => {
        return builder.addCase(verifyOTP.pending, (state: any) => {
            state.verifyLoginLoading = true

        }),
            builder.addCase(verifyOTP.fulfilled, (state: any, { payload }: any) => {
                state.verifyLoginLoading = false
                state.otp_token = payload?.data?.token?.otp_token
                state.verifyUser = payload?.data;
            }),
            builder.addCase(verifyOTP.rejected, (state: any) => {
                state.verifyLoginLoading = false

            }),
            builder.addCase(getlogin.pending, (state: any) => {
                state.loginLoading = true
                state.isAuth = false
                localStorage.setItem("isAuth", 'false');

            }),
            builder.addCase(getlogin.fulfilled, (state: any, { payload }: any) => {
                state.loginLoading = false
                state.token = payload.authentication.token.access_token
                state.isAuth = true;
                localStorage.setItem("access_token", payload.authentication.token.access_token);
                localStorage.setItem("refresh_token", payload.authentication.token.refresh_token);
                useEncrypt('userData', JSON.stringify(payload))
                localStorage.setItem('isAuth', 'true')
                state.refreshToken = payload.authentication.token.refresh_token
                state.user_id = payload.user_data.user_id
                state.loginData = payload
                state.modulesList = payload.modules_list.modules;
                state.redirectModule = payload.module_page;
            }),
            builder.addCase(getlogin.rejected, (state: any) => {
                state.loginLoading = false
                state.isAuth = false
                localStorage.setItem("isAuth", 'false');

            }),
            builder.addCase(getRefreshToken.pending, (state: any) => {
                state.isAuth = true
            }),
            builder.addCase(getRefreshToken.fulfilled, (state: any, { payload }: any) => {
                state.token = payload.token.access_token
                state.refreshToken = payload.token.refresh_token
                state.loginData.authentication.token.access_token = localStorage.setItem("access_token", payload.token.access_token);
                state.loginData.authentication.token.refresh_token = localStorage.setItem("refresh_token", payload.token.refresh_token)
                state.isAuth = true;

            }),
            builder.addCase(getRefreshToken.rejected, (state: any) => {
                state.isAuth = true
            })
    }
})

export const { setToken } = verifyLoginSlice.actions
export const { deleteToken } = verifyLoginSlice.actions
export const getAllModulesList = (state: any) => state.verifyLogin.modulesList;

export default verifyLoginSlice.reducer