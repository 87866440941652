import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Provider, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { AppDispatch, store } from './store/Store';
import CreateAxiosResponseInterceptor from './pages/Auth/components/axiosInterceptors';
import Loader from "./assets/images/loader.gif";
import { deleteToken, setToken } from './pages/Auth/components/verifyLoginSlice';
import { useDispatch } from 'react-redux';
import Toaster from './components/AlertToaster/AlertToaster'
import { init as initApm } from '@elastic/apm-rum'
import SidemenuContextprovider from './pages/Sidemenu/SidemenuContextprovider';
import ChargerDetailsProvider from './components/ChargerDetailsModal/ChargerDetailsProvider';
import NotFound from './pages/Error/404';

function App() {

  const LandingLayout = lazy(() => import('./layouts/LandingLayout'));
  const SideBarLayout = lazy(() => import('./layouts/SideBarLayout'));
  const Dashboard = lazy(() => import('./pages/Dashboard'));
  const RequiredAuth = lazy(() => import('./routes/RequiredAuth'));
  const Login = lazy(() => import('./pages/Auth').then(({ Login }) => ({ default: Login })));
  const OTPverification = lazy(() => import('./pages/Auth').then(({ OTPverification }) => ({ default: OTPverification })));
  const Admin = lazy(() => import("./pages/UserManagement/index"))
  const Setting = lazy(() => import("./pages/Settings/index"))
  const VendorManagement = lazy(() => import("./pages/VendorManagement/index"))
  const Installation = lazy(() => import("./pages/Operations/Installation"))
  const EvApplication = lazy(() => import("./pages/Operations/EvApplication"))
  const DealDetail = lazy(() => import("./pages/Operations/features/DealDetails/index"))
  const Logistics = lazy(() => import("./pages/Logistics/index"))
  const LogisticsDealDetail = lazy(() => import("./pages/Logistics/features/LogisticsDealDetail/index"))
  const CPODealDetail = lazy(() => import("./pages/Onboarding/features/CPO/CPODealDetail/index"))
  const HostDealDetail = lazy(() => import("./pages/Onboarding/features/Host/HostDealDetail/index"))
  const InvestorDealDetail = lazy(() => import("./pages/Onboarding/features/Investor/HostDealDetail/index"))
  const StationsDealDetail = lazy(() => import("./pages/Onboarding/features/Stations/StationsDealDetail/index"))
  const CPOs = lazy(() => import("./pages/Onboarding/CPO"))
  const Investors = lazy(() => import("./pages/Onboarding/Investors"))
  const Hosts = lazy(() => import("./pages/Onboarding/Host"))
  const Stations = lazy(() => import("./pages/Onboarding/Stations"))
  const Finance = lazy(() => import("./pages/Finance/index"))
  const Survey = lazy(() => import("./pages/Operations/Survey"))
  const Demolition = lazy(() => import("./pages/Operations/Demolition"))
  const SurveyDealDetails = lazy(() => import("./pages/Operations/features/Survey/features/Survey"))
  const FinanceDealDetail = lazy(() => import("./pages/Finance/features/FinanceDealDetail/index"))
  const TechSupportDealDetail = lazy(() => import("./pages/TechSupport/features/TechSupportDealDetail/index"))
  const CustomerSupport = lazy(() => import("./pages/CustomerSupport"))
  const TechSupport = lazy(() => import("./pages/TechSupport/index"))
  const NetworkReliability = lazy(() => import("./pages/NetworkReliability/index"))
  const NetworkReliabilityDealDetail = lazy(() => import("./pages/NetworkReliability/features/NetworkReliabilityDealDetail/index"))
  const CustomerSupportDealDetail = lazy(() => import("./pages/CustomerSupport/features/CustomerSupportDealDetail/index"))
  const DeliveryVendors = lazy(() => import("./pages/ThirdParty/features/DeliveryVendors"))
  const Distributors = lazy(() => import("./pages/ThirdParty/features/Distributors"))
  const NetworkReliabilityVendors = lazy(() => import("./pages/ThirdParty/features/NetworkReliabilityVendors"))
  const OperationVendors = lazy(() => import("./pages/ThirdParty/features/OpsVendors"))
  const Projects = lazy(() => import("./pages/Projects"))
  const ProcurementDealDetails = lazy(() => import("./pages/Procurement/features/ProcurementDealDetails/index"));
  const ProcurementHistory = lazy(() => import("./pages/Procurement/features/ProcurementDealDetails/ProcurementHistory"))
  const RegularDealsDealDetail = lazy(() => import("./pages/Projects/features/RegularDealsDealDetail/index"))
  const DemolitionDealDetail = lazy(() => import("./pages/Operations/features/Demolition/features/DemolitionDealDetail"))

  // Demolition


  const dispatch = useDispatch<AppDispatch>()

  const location = useLocation();

  CreateAxiosResponseInterceptor()

  useEffect(() => {
    // let isAuth = localStorage.getItem('isAuth')
    location.pathname === '/auth' ?
      dispatch(deleteToken())
      :
      dispatch(setToken())
  }, [])

  var apm = initApm({
    // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
    serviceName: 'crystalina-ui',
    // Set custom APM Server URL (default: http://localhost:8200)
    serverUrl: process.env.REACT_APP_APM_KEY,
    // Set the service version (required for source map feature)
    serviceVersion: '',
    // Set the service environment
    environment: process.env.NODE_ENV,
    active: true
  })

  const { isAuth } = useSelector((store: any) => store.verifyLogin);

  return (

    <Provider store={store}>
      <SidemenuContextprovider>
        <Toaster />
        <Suspense fallback={<div className='loader-container center'>
          <div>
            <img src={Loader} alt="" />
            <span>
              I'm going to walk EEVEE
            </span>
          </div>
        </div>}>

          <Routes>
            {/* Public Routes */}
            <Route element={<LandingLayout />}>
              <Route path='/auth' element={<Login />} />
              <Route path='/OTPverification' element={<OTPverification />} />
            </Route>

            {/* Private Routes */}
            <Route element={<RequiredAuth />}>
              <Route element={<SideBarLayout />}>
                {/* <Route path='/Procurement' element={< Procurement />} /> */}
                <Route path={'/Installation'} element={< Installation />} />
                <Route path={'/'} element={< Installation />} />
                <Route path='/DealDetails' element={<ChargerDetailsProvider><DealDetail /></ChargerDetailsProvider>} />
                <Route path='/SurveyDealDetails' element={<SurveyDealDetails />} />
                {/* <Route path='/ProcurementHistory' element={<ProcurementHistory />} /> */}
                <Route path='/Applications' element={<EvApplication />} />
                {/* <Route path='/ProcurementDealDetails' element={< ProcurementDealDetails />} /> */}
                <Route path='/Vendors' element={<VendorManagement />} />
                <Route path='/Logistics' element={<Logistics />} />
                <Route path='/LogisticsDealDetails' element={<ChargerDetailsProvider><LogisticsDealDetail /></ChargerDetailsProvider>} />
                <Route path='/Finance' element={<Finance />} />
                <Route path='/Survey' element={<Survey />} />
                <Route path='/Demolition' element={<Demolition />} />
                <Route path='/CustomerSupport' element={<CustomerSupport />} />
                <Route path='/TechSupport' element={<TechSupport />} />
                <Route path='/NetworkReliability' element={<NetworkReliability />} />
                <Route path='/CPO' element={<CPOs />} />
                <Route path='/Investors' element={<Investors />} />
                <Route path='/Stations' element={<Stations />} />
                <Route path='/Host' element={<Hosts />} />
                <Route path='/HostDealDetails' element={<HostDealDetail />} />
                <Route path='/InvestorDealDetails' element={<InvestorDealDetail />} />
                <Route path='/CPODealDetails' element={<CPODealDetail />} />
                <Route path='/StationsDealDetails' element={<ChargerDetailsProvider><StationsDealDetail /></ChargerDetailsProvider>} />
                <Route path='/FinanceDealDetails' element={<ChargerDetailsProvider><FinanceDealDetail /></ChargerDetailsProvider>} />
                <Route path='/DemolitionDealDetails' element={<ChargerDetailsProvider><DemolitionDealDetail /></ChargerDetailsProvider>} />
                <Route path='/TechSupportDealDetails' element={<ChargerDetailsProvider><TechSupportDealDetail /></ChargerDetailsProvider>} />
                <Route path='/NetworkReliabilityDealDetails' element={<ChargerDetailsProvider><NetworkReliabilityDealDetail /></ChargerDetailsProvider>} />
                <Route path='/CustomerSupportDealDetails' element={<ChargerDetailsProvider><CustomerSupportDealDetail /></ChargerDetailsProvider>} />
                <Route path='/RegularDealsDealDetail' element={<ChargerDetailsProvider><RegularDealsDealDetail /></ChargerDetailsProvider>} />
                <Route path='/Admin' element={<Admin />} />
                <Route path='/Settings' element={<Setting />} />
                <Route path={'/DeliveryVendors'} element={<DeliveryVendors />} />
                {/* <Route path={'/Distributors'} element={<Distributors />} /> */}
                <Route path={'/NetworkReliabilityVendors'} element={<NetworkReliabilityVendors />} />
                <Route path={'/OperationVendors'} element={<OperationVendors />} />
                <Route path={'/Projects'} element={<Projects />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Route>
          </Routes>
        </Suspense>
      </SidemenuContextprovider>
    </Provider>

  );
}

export default App;
