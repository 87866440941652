import { api } from "../../api"
import { setAlertFunc } from "../../components/AlertToaster/AlertSlice";
import {
    HistoryResponse,
    IResponse,
    StatusButton,
    TabsResponseType,
    TicketDataResponseType,
} from "../Common/commonTypes"

const MODULE_URL = `finance`
interface GetSkipStatusesParams {
    search_key?: string;
    ticket_id: string
}
const financeService = api.injectEndpoints({
    endpoints: build => ({
        getFinanceTabs: build.query({
            query: params => ({
                url: `${MODULE_URL}/ticket_total`,
                params: {
                    state_id: JSON.stringify(params.data.stateId ?? []),
                    status_id: JSON.stringify(params.data.statusId ?? []),
                    city_id: JSON.stringify(params.data.cityId ?? []),
                    assigned_to: params.data.assigned_to?.id ?? "",
                    age: JSON.stringify(params.data.age ?? []),
                    age_op: params.data.age_op ?? "",
                    raised_on_end: params.data.TicketEndDate ?? "",
                    raised_on_start: params.data.TicketStartDate ?? "",
                    due_start: params.data.ClosureStartDate ?? "",
                    due_end: params.data.ClosureEndDate ?? "",
                    charger_type: JSON.stringify(params.data.chargerIds ?? []),
                    search_key: params.data.search_key ?? "",
                    read_filter: params.data.ReadUnread ?? "all",
                    per_page: params?.selectedValue,
                    page: params?.page,
                },
            }),
            transformResponse: (response: TabsResponseType) => response.data.message,
            providesTags: ["Finance"],
        }),
        getFinanceData: build.query<TicketDataResponseType, { data: any; selectedValue: number; page: number }>({
            query: params => ({
                url: `${MODULE_URL}/${params.data.parent_status ?? "all_tab"}`,
                params: {
                    state_id: JSON.stringify(params.data.stateId ?? []),
                    status_id: JSON.stringify(params.data.statusId ?? []),
                    city_id: JSON.stringify(params.data.cityId ?? []),
                    assigned_to: params.data.assigned_to?.id ?? "",
                    age: JSON.stringify(params.data.age ?? []),
                    age_op: params.data.age_op ?? "",
                    raised_on_end: params.data.TicketEndDate ?? "",
                    raised_on_start: params.data.TicketStartDate ?? "",
                    due_start: params.data.ClosureStartDate ?? "",
                    due_end: params.data.ClosureEndDate ?? "",
                    charger_type: JSON.stringify(params.data.chargerIds ?? []),
                    search_key: params.data.search_key ?? "",
                    read_filter: params.data.ReadUnread ?? "all",
                    per_page: params?.selectedValue,
                    page: params?.page,
                },
            }),
            providesTags: ["Finance"],
        }),
        getTicketDetails: build.query<IResponse<any>, { ticket_id: string }>({
            query: ({ ticket_id }) => ({
                url: `${MODULE_URL}/ticket_details`,
                params: {
                    ticket_id,
                },
            }),
            providesTags: (result, error, arg) => [{ type: "Finance", id: arg.ticket_id }],
        }),
        getOtherDetails: build.query<IResponse<any>, { ticket_id: string }>({
            query: ({ ticket_id }) => ({
                url: `${MODULE_URL}/other_details`,
                params: {
                    ticket_id,
                },
            }),
            providesTags: (result, error, arg) => [{ type: "Finance", id: arg.ticket_id }],
        }),
        getSkipStatuses: build.query<IResponse<any>, GetSkipStatusesParams>({
            query: ({ search_key, ticket_id }) => ({
                url: `${MODULE_URL}/statuses`,
                params: {
                    search_key: search_key ?? '',
                },
            }),
            providesTags: (result, error, arg) => [{ type: "Finance", id: arg?.ticket_id }],
        }),
        getTicketHistory: build.query<HistoryResponse, { ticket_id: string }>({
            query: ({ ticket_id }) => ({
                url: `${MODULE_URL}/ticket_history`,
                params: {
                    ticket_id,
                },
            }),
            providesTags: (result, error, arg) => [{ type: "Finance", id: arg.ticket_id }],
        }),
        getPaymentConditions: build.query<HistoryResponse, { ticket_id: string }>({
            query: ({ ticket_id }) => ({
                url: `${MODULE_URL}/conditions`,
                params: {
                    ticket_id,
                },
            }),
            providesTags: (result, error, arg) => [{ type: "Finance", id: arg.ticket_id }],
        }),
        getPaymentTime: build.query<HistoryResponse, { ticket_id: string }>({
            query: () => ({
                url: `${MODULE_URL}/time_uom`,
            }),
            providesTags: (result, error, arg) => [{ type: "Finance", id: arg.ticket_id }],
        }),
        // for getting button dropdown options
        getFinanceButtonDropdown: build.query({
            query: (params: { ticket_id: string }) => ({
                url: `${MODULE_URL}/button`,
                params: params,
            }),
            transformResponse: (response: IResponse<StatusButton[]>) => response.data,
            providesTags: (result, error, arg) => [{ type: "Finance", id: arg.ticket_id }],
        }),
        // for changing ticket status
        editFinanceTicketStatus: build.mutation<IResponse<unknown>, FormData>({
            query: body => ({
                url: `${MODULE_URL}/ticket`,
                method: "PUT",
                body: body,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setAlertFunc({ type: "success", msg: data?.data }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error.message }))
                }
            },
            invalidatesTags: (result, error, arg) => [
                { type: "Finance", id: arg.get("ticket_id") as string },
                "Finance",
            ],
        }),
        editSkipStatus: build.mutation<IResponse<unknown>, FormData>({
            query: body => ({
                url: `${MODULE_URL}/skip_to_status`,
                method: "PUT",
                body: body,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setAlertFunc({ type: "success", msg: data?.data }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error.message }))
                }
            },
            invalidatesTags: (result, error, arg) => [
                { type: "Finance", id: arg.get("ticket_id") as string },
                "Finance",
            ],
        }),
        editTaxInvoice: build.mutation<IResponse<unknown>, FormData>({
            query: body => ({
                url: `${MODULE_URL}/tax_invoice`,
                method: "PUT",
                body: body,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setAlertFunc({ type: "success", msg: data?.data }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error.message }))
                }
            },
            invalidatesTags: (result, error, arg) => [
                { type: "Finance", id: arg.get("ticket_id") as string },
                "Finance",
            ],
        }),
        addTicketDetails: build.mutation<IResponse<unknown>, FormData>({
            query: body => ({
                url: `${MODULE_URL}/ticket_details`,
                method: "POST",
                body: body,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setAlertFunc({ type: "success", msg: data?.data }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error.message }))
                }
            },
            invalidatesTags: (result, error, arg) => [
                { type: "Finance", id: arg.get("ticket_id") as string },
                "Finance",
            ],
        }),
        addPaymentTerm: build.mutation<IResponse<unknown>, FormData>({
            query: body => ({
                url: `${MODULE_URL}/payment_term`,
                method: "POST",
                body: body,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setAlertFunc({ type: "success", msg: data?.data }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error.message }))
                }
            },
            invalidatesTags: (result, error, arg) => [
                { type: "Finance", id: arg.get("ticket_id") as string },
                "Finance",
            ],
        }),
        addTransaction: build.mutation<IResponse<unknown>, FormData>({
            query: body => ({
                url: `${MODULE_URL}/transaction`,
                method: "POST",
                body: body,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setAlertFunc({ type: "success", msg: data?.data }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error.message }))
                }
            },
            invalidatesTags: (result, error, arg) => [
                { type: "Finance", id: arg.get("ticket_id") as string },
                "Finance",
            ],
        }),
        
        addTaxInvoice: build.mutation<IResponse<unknown>, FormData>({
            query: body => ({
                url: `${MODULE_URL}/tax_invoice`,
                method: "POST",
                body: body,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setAlertFunc({ type: "success", msg: data?.data }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error.message }))
                }
            },
            invalidatesTags: (result, error, arg) => [
                { type: "Finance", id: arg.get("ticket_id") as string },
                "Finance",
            ],
        }),
        VerifyTransaction: build.mutation<IResponse<unknown>, FormData>({
            query: body => ({
                url: `${MODULE_URL}/verify_transaction`,
                method: "POST",
                body: body,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setAlertFunc({ type: "success", msg: data?.data }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error.message }))
                }
            },
            invalidatesTags: (result, error, arg) => [
                { type: "Finance", id: arg.get("ticket_id") as string },
                "Finance",
            ],
        }),
        deleteTransaction: build.mutation<IResponse<any>, { data: any }>({
            query: ({ data }) => ({
                url: `${MODULE_URL}/transaction?ticket_id=${data?.ticket_id}&user_id=${data?.user_id}&transaction_id=${data?.transaction_id}`,
                method: "DELETE",
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setAlertFunc({ type: "success", msg: data?.data }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error.message }))
                }
            },
            invalidatesTags: (result, error, arg) => [
                { type: "Finance", id: arg?.data?.ticket_id as string },
                "Finance",
            ],
        }),
        deletePaymentTerm: build.mutation<IResponse<any>, { data: any }>({
            query: ({ data }) => ({
                url: `${MODULE_URL}/payment_term?ticket_id=${data?.ticket_id}&user_id=${data?.user_id}&payment_term_id=${data?.payment_term_id}`,
                method: "DELETE",
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setAlertFunc({ type: "success", msg: data?.data }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error.message }))
                }
            },
            invalidatesTags: (result, error, arg) => [
                { type: "Finance", id: arg?.data?.ticket_id as string },
                "Finance",
            ],
        }),
        deleteTaxInvoice: build.mutation<IResponse<any>, { data: any }>({
            query: ({ data }) => ({
                url: `${MODULE_URL}/tax_invoice?ticket_id=${data?.ticket_id}&user_id=${data?.user_id}&id=${data?.id}`,
                method: "DELETE"
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setAlertFunc({ type: "success", msg: data?.data }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error?.message }))
                }
            },
            invalidatesTags: (result, error, arg) => [
                { type: "Finance", id: arg?.data?.ticket_id as string },
                "Finance",
            ],
        }),
    })
})
export const {
    useLazyGetFinanceTabsQuery,
    useLazyGetFinanceDataQuery,
    useGetTicketDetailsQuery,
    useGetTicketHistoryQuery,
    useGetFinanceButtonDropdownQuery,
    useGetSkipStatusesQuery,
    useGetOtherDetailsQuery,
    useGetPaymentConditionsQuery,
    useGetPaymentTimeQuery,
    useEditFinanceTicketStatusMutation,
    useEditSkipStatusMutation,
    useEditTaxInvoiceMutation,
    useVerifyTransactionMutation,
    useAddPaymentTermMutation,
    useAddTaxInvoiceMutation,
    useAddTicketDetailsMutation,
    useAddTransactionMutation,
    useDeletePaymentTermMutation,
    useDeleteTaxInvoiceMutation,
    useDeleteTransactionMutation
} = financeService

export { financeService }